.slick-slide {
  transition:
    transform 0.3s ease,
    filter 0.3s ease; /* Add transition for smooth effect */
  filter: blur(3px);
}

/* Remove blur from active slide */
.slick-slide.slick-active {
  filter: blur(0);
}
